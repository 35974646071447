export default {
  insurance: 'Understanding Flood Insurance',
  retrofits: 'Flood Retrofits',
  smallBusinesses: 'Small Business',
  stormwaterFlooding: 'Stormwater Flooding',
  hurricane: 'Hurricane Season',
  climateChange: 'Climate Change',
  audit: 'Elevation Certificates',
  floodRecovery: 'Flood Recovery Fund'
};
