import inspection from '../images/inspection.svg';
import graphIncreasing from '../images/graph-increasing.svg';
import glasses from '../images/glasses.svg';
import umbrella from '../images/umbrella.svg';
import chanceOfStorm from '../images/chance-of-storm.svg';
import multifamily from '../images/building-icon-fhny.svg';

export default {
  header: 'What to Know',
  whatToKnowSmallBusinessItems: {
    rain: {
      image: chanceOfStorm,
      header: 'If it can rain, it can flood.',
      body:
        'You may want to purchase flood insurance coverage if you are in an area that is at risk of flooding. If your property is at risk for stormwater flooding consider purchasing a sewer backup and/or flood insurance policy. These policies could cover the damage and cleanup costs resulting from sewer backups and flooding caused by heavy rain.'
    },
    smallBusiness: {
      image: umbrella,
      header:
        'Business property insurance may not cover the cost of damage caused by flooding to your inventory or equipment.',
      body:
        'Be flood smart and talk to a broker about the <a href="https://www.fema.gov/flood-insurance" target="_blank" rel="noopener noreferrer">National Flood Insurance Program (NFIP)</a> to protect your business from flooding.'
    },
    understandOptions: {
      image: multifamily,
      header:
        'Building owners need to understand their flood insurance options.',
      body:
        'Neither homeowner nor renters insurance covers flood damage. Learn your risk of different types of flooding and consider your flood insurance options to ensure your structure and contents are protected in the event of a flood.'
    }
  },
  whatToKnowHomeownerItems: {
    increasingPremiums: {
      image: graphIncreasing,
      header: 'Flood insurance premiums are increasing significantly.',
      body:
        'In 2012, Congress eliminated subsidies on older homes; at the same time, flood risk is also increasing because of climate change. This means more New Yorkers will face higher premiums, especially when the maps are updated.'
    },
    legacyRate: {
      image: glasses,
      header: 'If you buy now, save later. You may get a lower legacy rate.',
      body:
        'Buying flood insurance before new maps take effect may mean much cheaper premiums over the long term.'
    },
    currentRate: {
      image: inspection,
      header: 'Checking your current rate is critical.',
      body:
        'FEMA sets flood insurance prices for all homes, regardless of your insurance company. But, each home is unique and the price for flood insurance will vary from property to property. Learn more about your flood insurance options <a href="https://www.floodhelpny.org/en/understanding-flood-insurance" target="_blank" rel="noopener noreferrer">here</a>.'
    },
    rain: {
      image: chanceOfStorm,
      header: 'If it can rain, it can flood.',
      body:
        'You may want to purchase flood insurance coverage if you are in an area that is at risk for stormwater flooding.'
    },
    contentsInsurance: {
      image: umbrella,
      header:
        'Renters can purchase flood insurance for just the contents of their apartment.',
      body:
        'If your building floods, your landlord’s flood insurance will protect the building you rent in, but your personal belongings will not be protected. Renters insurance policies are fairly inexpensive, starting at about $100 a year and protecting renters from thousands of dollars in flood damages.'
    },
    understandOptions: {
      image: multifamily,
      header:
        'Building owners need to understand their flood insurance options.',
      body:
        'Homeowners insurance does not cover flood damage. Learn your risk of different types of flooding and consider your flood insurance options to ensure your structure and contents are protected in the event of a flood.'
    }
  }
};
