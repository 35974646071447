import smallHome from '../images/small-home.png';
import homeAutomation from '../images/home-automation.svg';
import assess from '../images/assess.svg';
import stormwaterFlooding from '../images/stormwater-flooding.svg';
import heavyRain from '../images/heavy-rain.svg';
import climateChange from '../images/climate-change.svg';
import certificate from '../images/certificate.svg';
import moneyHouse from '../images/money-house.svg';

const resources = [
  {
    slug: 'understanding-flood-insurance',
    alt: 'home',
    providerKey: 'insurance',
    src: smallHome
  },
  {
    slug: 'flood-retrofits',
    alt: 'house with gears',
    providerKey: 'retrofits',
    src: homeAutomation
  },
  {
    slug: 'small-business',
    alt: 'small businesses',
    providerKey: 'smallBusinesses',
    src: assess
  },
  {
    slug: 'stormwater-flooding',
    alt: 'stormwater flooding',
    providerKey: 'stormwaterFlooding',
    src: stormwaterFlooding
  },
  {
    slug: 'prepare-for-hurricane-season',
    alt: 'heavy rain',
    providerKey: 'hurricane',
    src: heavyRain
  },
  {
    slug: 'climate-change',
    alt: 'climate change',
    providerKey: 'climateChange',
    src: climateChange
  },
  {
    slug: 'elevation-certificates',
    alt: 'certificate',
    providerKey: 'audit',
    src: certificate
  },
  {
    slug: 'flood-recovery-program',
    alt: 'flood recovery fund',
    providerKey: 'floodRecovery',
    src: moneyHouse
  }
];

export default resources;
